import React from 'react';
import './App.css';


const SearchBar = ({ classname, searchQuery, setSearchQuery, handleSearch, isMobile }) => {
    return (
        <div className={classname}>
            <div className="search-wrapper">
                {/* for desktop */}
                {!isMobile && !searchQuery && (<label for="search-input" className={"search-placeholder"}>
                    <span style={{color:'black'}}>LOCATE</span> ANYthing (BE <b>VERY</b> SPECIFIC!)
                </label>)}
                {/* for mobile */}
                {isMobile && !searchQuery && (<label for="search-input" className="search-placeholder">
                    <span className='placeholder-line1'><span style={{color:'black'}}>LOCATE</span> ANYthing</span> <br/> <span className='placeholder-line2'> (BE <b>VERY</b> SPECIFIC!) </span>
                </label>)}
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(); }}
                    placeholder=" "
                    className="search-input"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="The more search terms you enter, the better results you will get. For example: if you're looking for a used item, put 'used'; if a specific brand, specify the brand, etc."
                />
                <button onClick={handleSearch} className="search-button">
                    <i className="fa fa-search" />
                </button>
            </div>
        </div>
    );
};

export default SearchBar;