import React , {useState} from "react";
import './css/Contact-Form.css';

const ContactForm = ({ closeContactForm }) => {
    const [formData, setFormData] = useState({
        sender_name: "",
        sender_email: "",
        message: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFocusedName, setIsFocusedName] = useState(false);
    const [isFocusedEmail, setIsFocusedEmail] = useState(false);
    const [isFocusedMessage, setIsFocusedMessage] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleBlur = (field) => {
        if (field === "sender_name" && formData.sender_name === "") {
            setIsFocusedName(false);
        }
        if (field === "sender_email" && formData.sender_email === "") {
            setIsFocusedEmail(false);
        }
        if (field === "message" && formData.message === "") {
            setIsFocusedMessage(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch("https://api.mapr.ad/send-email/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
                mode: 'cors',
            });

            if (response.ok) {
                alert("Thank you! Your message has been sent.");
                setFormData({ sender_name: "", sender_email: "", message: "" });
                closeContactForm();
            } else {
                alert("Something went wrong. Please try again.");
            }
        } catch (error) {
            alert("Error submitting the form. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="contact-form-container">
            <button className="close-btn-tt" onClick={closeContactForm}>
                &times;
            </button>
            <form onSubmit={handleSubmit}>
                <p style={{ margin: '0px 0px 4px 0px' }}>Contact Us</p>

                {!isFocusedName && formData.sender_name === "" && (
                    <label style={{ top: "16.5%" }}>Name</label>
                )}
                <input
                    type="text"
                    name="sender_name"
                    value={formData.sender_name}
                    onFocus={() => setIsFocusedName(true)}
                    onBlur={() => handleBlur("sender_name")}
                    onChange={handleChange}
                    required
                />

                {!isFocusedEmail && formData.sender_email === "" && (
                    <label style={{ top: "32%" }}>Email</label>
                )}
                <input
                    type="email"
                    name="sender_email"
                    value={formData.sender_email}
                    onFocus={() => setIsFocusedEmail(true)}
                    onBlur={() => handleBlur("sender_email")}
                    onChange={handleChange}
                    required
                />

                {!isFocusedMessage && formData.message === "" && (
                    <label style={{ top: "47.5%" }}>Message</label>
                )}
                <textarea
                    name="message"
                    rows="4"
                    value={formData.message}
                    onFocus={() => setIsFocusedMessage(true)}
                    onBlur={() => handleBlur("message")}
                    onChange={handleChange}
                    required
                ></textarea>

                <button className='submit-button-contact' type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Sending..." : "Submit"}
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
