import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './css/App.css';
import glObr_Icon from './images/glObr_Icon.png';
import location_search_icon from './images/location-search.png';
import spinD from './images/spinD.gif';
import questionMark from './images/question-mark.png';
import paypal from './images/paypal.png';
import paypalBtn from './images/paypalBtn.png';
import SearchBar from './SearchBar';
import 'leaflet-geosearch/dist/geosearch.css';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import Tooltip from './Tooltip';
import { categories, languageOptions } from './utility';
import ContactForm from './ContactForm';

// Custom icons
const HomeIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/25/25694.png',
  iconSize: [38, 38],
  iconAnchor: [19, 38],
});

const DefaultPinIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/64/64572.png',
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

const App = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const markerRefs = useRef([]); // Array to store references to markers
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [ip_address, setIPv4] = useState('');
  const [showDonateButton, setShowDonateButton] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState((window.innerWidth <= 768) ||
    (window.innerWidth === 1080 && window.innerHeight === 2340) || (window.innerWidth === 1080 && window.innerHeight === 2400)
    || (window.innerWidth === 800 && window.innerHeight === 1280));
  const [searchCount, setSearchCount] = useState(0);
  const [language, setSelectedLanguage] = useState('English');
  const [map, setMap] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const searchControlRef = useRef(null);
  const [isTopSearchActive, setIsTopSearchActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showContact, setShowContact] = useState(true);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const handleContactIconClick = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };

  useEffect(() => {
    setShowTooltip(true);
  }, []);

  const handleClose = () => {
    setShowTooltip(false); 
  };

  useEffect(() => {
    if (map) {
      map.setZoom(14);
      console.log(map,"---------------")
    }
  }, [14, map]);

  const handleTopSearch = () => {
    setIsTopSearchActive(false);
  }

  const handleSearchButtonClick = () => {
    setShowSearchBar((prev) => !prev);
    setShowLanguageDropdown(false)
    isMobile && setShowContact((prev) => !prev);
  };

  const handleSearchSubmit = () => {
    setShowSearchBar(false);
    isMobile && setShowContact(true);
  };

  const handleCategoryChange = (event) => {
    setShowTooltip(false);
    const _wordCount = searchQuery.trim().split(/\s+/).filter(word => word).length;

    if(_wordCount>3){
      handleSearch();
    }
    setSelectedCategory(event.target.value);
    console.log('Selected Category:', event.target.value);
    // Add logic to handle category selection, like filtering results
  };

  const GeoSearchComponent = () => {
    //const map = useMap();

    useEffect(() => {
      // Override Leaflet's default icons globally
      L.Icon.Default.mergeOptions({
        iconUrl: 'https://cdn-icons-png.flaticon.com/512/25/25694.png',
        iconRetinaUrl: 'https://cdn-icons-png.flaticon.com/512/25/25694.png',
        shadowUrl: '', // Disable shadow
      });

      if (!showSearchBar) {
        if (searchControlRef.current) {
          map.removeControl(searchControlRef.current);
        }
        return;
      }

      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider,
        style: 'bar',
        autoClose: true,
        searchLabel: 'Enter location...',
        keepResult: true,
      });

      

      searchControlRef.current = searchControl;
      map.addControl(searchControl);

      // Listen to search events
      map.on('geosearch/showlocation', (result) => {
        handleSearchSubmit();
        const labelArray = result.location.label.split(',');
        const lastElement = labelArray.pop();
        setCountry(lastElement);
        if(labelArray.length > 0){
          setCity(labelArray[0])
        }
        else{
          setCity('')
        }
        map.setView([result.location.y, result.location.x], 12);
    
        let bounding_box = [];
        if (map) {
            const bounds = map.getBounds();
            bounding_box = [
                bounds.getNorthEast().lng, // lng_east
                bounds.getSouthWest().lng, // lng_west
                bounds.getNorthEast().lat, // lat_north
                bounds.getSouthWest().lat  // lat_south
            ];
            console.log(bounding_box);
        }
    
      setTimeout(() => {
            if (searchQuery.trim().length > 0) {
                setSearchCount(prevState => prevState + 1);
    
                setLoading(true);
    
                const requestData = {
                    query: searchQuery || '',
                    city: city || '',
                    country: lastElement.trim() || '',
                    ip_address: ip_address || '',
                    language: language || 'english',
                    bounding_box: bounding_box,
                    selectedCategory: selectedCategory
                };
    
                console.log("Request Data:", requestData); // Debug the data being sent
    
                fetch('https://api.mapr.ad/find-taxonomy/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData), // Ensure no circular structures
                    mode: 'cors',
                })
                    .then(response => response.json())
                    .then(data => {
                        const newMarkers = data.map(item => ({
                            image: item.image || 'https://via.placeholder.com/150',
                            lat: item.lat,
                            lng: item.lng,
                            title: item.title || 'No Title',
                            address: item.address || '',
                            website: item.website || 'No Website',
                        }));
                        setMarkers(newMarkers);
    
                        if (map && newMarkers.length > 0) {
                            const bounds = L.latLngBounds(newMarkers.map(marker => [marker.lat, marker.lng]));
                            map.fitBounds(bounds, { padding: [50, 50] });
                        }
                    })
                    .catch(error => console.error("Error searching data: ", error))
                    .finally(() => setLoading(false));
            }
        }, 1500); // Delay of 5 seconds
      });
    

      return () => {
        if (searchControlRef.current) {
          map.removeControl(searchControlRef.current);
        }
      };
    }, [showSearchBar, map]);

    return null;
  };




  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    setShowLanguageDropdown(false);
    isMobile && setShowContact(true);

    if (searchQuery.trim().length > 0) {
      //alert(newLanguage)
      setSearchCount(prevState => prevState + 1);
  
      setLoading(true);
      let boundsArrayLang = []
      if (map){
        const bounds = map.getBounds();
        boundsArrayLang = [
          bounds.getNorthEast().lng, // lng_east
          bounds.getSouthWest().lng, // lng_west
          bounds.getNorthEast().lat, // lat_north
          bounds.getSouthWest().lat  // lat_south
        ];
        console.log(boundsArrayLang);
      }
    
      const requestData = {
        query: searchQuery || '',
        city: city || '',
        country: country || '',
        ip_address: ip_address || '',
        language: newLanguage || 'english',
        bounding_box:boundsArrayLang,
        selectedCategory:selectedCategory
      };
    
      console.log("Request Data:", requestData); // Debug the data being sent
    
      fetch('https://api.mapr.ad/find-taxonomy/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData), // Ensure no circular structures
        mode: 'cors',
      })
        .then(response => response.json())
        .then(data => {
          const newMarkers = data.map(item => ({
            image: item.image || 'https://via.placeholder.com/150',
            lat: item.lat,
            lng: item.lng,
            title: item.title || 'No Title',
            address: item.address || '',
            website: item.website || 'No Website',
          }));
          setMarkers(newMarkers);
    
          if (map && newMarkers.length > 0) {
            const bounds = L.latLngBounds(newMarkers.map(marker => [marker.lat, marker.lng]));
            map.fitBounds(bounds, { padding: [50, 50] });
          }
        })
        .catch(error => console.error("Error searching data: ", error))
        .finally(() => setLoading(false));
    }
    //loadGoogleTranslate(newLanguage); // Reinitialize Google Translate
  };



  const moveToLand = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=14ca0f2cb4364c299b67b0bf7ac1bd4e`
      );
      const data = await response.json();
  
      if (data && data.results && data.results.length > 0) {
        const result = data.results[0];
  
        if (result.components.water || result.components.ocean) {
          // Adjust to nearest land (this might vary depending on the API used)
          const landLocation = result.annotations.nearest?.location;
          if (landLocation) {
            return [landLocation.lat, landLocation.lng];
          }
        }
      }
      return [lat, lng]; // Return original if no adjustment needed
    } catch (error) {
      console.error("Error finding nearest land:", error);
      return [lat, lng];
    }
  };

  const handleResize = () => {
    setIsMobile((window.innerWidth <= 768)
      || (window.innerWidth === 1080 && window.innerHeight === 2340)
      || (window.innerWidth === 1080 && window.innerHeight === 2400)
      || (window.innerWidth === 800 && window.innerHeight === 1280));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDonateClick = () => {
    console.log("---------------paypal clicked----------")
    window.open(
      'https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=d@dvot.org&lc=US&no_note=0&item_name=Donate&cn=&currency_code=USD&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHosted',
      '_blank'
    );
    console.log("---------------paypal clicked again----------")
  }

  const handleDonateClose = () => {
    setShowDonateButton(false);
  }

  useEffect(() => {
    async function fetchLocation() {
      try {
        // First fetch: Get user location
        const response = await fetch("https://api.ipgeolocation.io/ipgeo?apiKey=30d33b1d583c4da99abb20dfc129a12b");
        if (!response.ok) throw new Error("Failed to fetch IP location");
  
        const data = await response.json();
        setUserLocation([data.latitude, data.longitude]);
        setCity(data.city || "Unknown City");
        setCountry(data.country_name || "Unknown Country");
        setIPv4(data.ip || "N/A");
  
        console.log("User Location Fetched:", data);
  
        // Second fetch: Send location data to Slack Webhook
        const webhookUrl = "https://api.mapr.ad/send-webhook/";
  
        const webhookResponse = await fetch(webhookUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ city: data.city, ip: data.ip }),
        });
  
        if (!webhookResponse.ok) throw new Error("Failed to send Slack Webhook");
  
        console.log("Slack Webhook Sent Successfully");
  
      } catch (error) {
        console.error("Error:", error);
      }
    }
  
    fetchLocation();
  }, []);
  
  const handleFilter = () => {
    const itemList = document.getElementById("item-list");
    let catDropdown;
    if(!isMobile){
      catDropdown = document.getElementById("cat-d");
    }else{
      catDropdown = document.getElementById("cat-dm");
    }
    
    itemList.innerHTML = "";
  
    const _wordCount = searchQuery.trim().split(/\s+/).filter(word => word).length;

    if (_wordCount >= 3) {
      itemList.style.display = "block";   

      const isCatDropdownVisible = catDropdown && catDropdown.style.display === "block";

      if(itemList.classList.contains("scrollable-list-dc") || itemList.classList.contains("scrollable-list-mc")){
        isCatDropdownVisible? 
        itemList.style.setProperty("--scrollable-list-top", "50%") : 
        itemList.style.setProperty("--scrollable-list-top", "76%");
      
      }else if(itemList.classList.contains("scrollable-list-dt")){
        itemList.style.setProperty("--scrollable-list-top", "100%");
      }

      categories.forEach((cat) => {
        const div = document.createElement("div");
        div.textContent = cat;
        div.className = "list-item"; 
        div.onclick = () => {
          setShowTooltip(false);
          setSelectedCategory(cat); 
          itemList.style.display = "none"; 
          if(catDropdown) catDropdown.style.display = "block";

          handleSearch();
        };
        itemList.appendChild(div);
      });
    } else {
      itemList.style.display = "none";
    }
  };

  const handleSearch = async () => {
    setShowTooltip(false);
    setSearchCount((prevState) => prevState + 1);
    const searchContainer = document.querySelector('.search-container-mc.search-cat-container-m');
    
    if (searchContainer && searchContainer.classList.contains('search-cat-container-m')) {
      setIsTopSearchActive(true); 
    }

    setLoading(true);
    //console.log(map.getBounds(),"00000000000000000000000000000")
    let boundsArray = []
    if (map){
      const bounds = map.getBounds();
      boundsArray = [
        bounds.getNorthEast().lng, // lng_east
        bounds.getSouthWest().lng, // lng_west
        bounds.getNorthEast().lat, // lat_north
        bounds.getSouthWest().lat  // lat_south
      ];
      console.log(boundsArray);
    }
    const requestData = {
      query: searchQuery || '',
      city: city || '',
      country: country || '',
      ip_address: ip_address || '',
      language: language || 'English',
      bounding_box:boundsArray,
      category: selectedCategory,
    };
  
    try {
      const response = await fetch('https://api.mapr.ad/find-taxonomy/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        mode: 'cors',
      });
  
      const data = await response.json();
  
      const updatedMarkers = await Promise.all(
        data.map(async (item) => {
          const [adjustedLat, adjustedLng] = await moveToLand(item.lat, item.lng);
          return {
            image: item.image || 'https://via.placeholder.com/150',
            lat: adjustedLat,
            lng: adjustedLng,
            title: item.title || 'No Title',
            address: item.address || '',
            website: item.website || 'No Website',
          };
        })
      );
  
      setMarkers(updatedMarkers);
  
      if (map && updatedMarkers.length > 0) {
        const bounds = L.latLngBounds(updatedMarkers.map((marker) => [marker.lat, marker.lng]));
        map.fitBounds(bounds, { padding: [50, 50] });
      }
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  

  const handleGifClick = () => {
    setShowDonateButton(prevState => !prevState);
  };
  
  const handleLanguageIcon = () => {
    setShowLanguageDropdown(prevState => !prevState);
    setShowSearchBar(false);
    isMobile && setShowContact((prev) => !prev);
  };

  const handleInfoBoxClick = (marker, index, lat, lng) => {
    console.log(markerRefs)
    if (markerRefs.current[index]) {
      // Move the map to the marker's location
      console.log(`Moving to: ${marker.lat}, ${marker.lng}`); // Debugging log


      // Open the marker's popup
      markerRefs.current[index].openPopup();
    } else {
      console.error("Marker reference or Map reference is missing!", markerRefs.current[index]);
    }
  };

  // Reset the markerRefs array to match the length of markers
  useEffect(() => {
    markerRefs.current = markerRefs.current.slice(0, markers.length);
    if (map && markers.length > 0) {
      const bounds = L.latLngBounds(markers.map(marker => [marker.lat, marker.lng]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [markers]);

  

  return (
    <div className='mapr-container'>
      {isMobile && (
        <>
          <div className="top-panel">
            <div className="language-selector">
              <img
                src={glObr_Icon}
                alt="Language Selector"
                className="language-icon"
                // style={{ height: '30px', width: '30px' }}
                onClick={handleLanguageIcon}
              />
            </div>


            {isTopSearchActive && (
              <div style={{ display: 'contents' }} onClick={handleTopSearch}>
                <SearchBar
                  classname="search-container"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleSearch={handleSearch}
                  isMobile={isMobile}
                  handleFilter={handleFilter}
                />
              </div>
            )}

            <div className={`${!searchCount ? "geosearch-tp":'language-selector'}`}>
              <img
                // src={`${!searchCount ? location_search_icon:spinD}`}
                src={location_search_icon}
                // alt={`${!searchCount ? "Location Search":"Donate"}`}
                alt="Location Search"
                // className={`${!searchCount ? "geo-icon-tp":"donate-icon"}`}
                className="geo-icon-tp"
                // onClick={searchCount ? handleGifClick : handleSearchButtonClick}
                onClick={handleSearchButtonClick}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
          {showContact && (
            <>
            <div className="contact-icon-m">
              <img
                src={questionMark}
                alt="Contact"
                onClick={handleContactIconClick}
                style={{height:"100%",width:"100%"}}
              />
            </div>
            {isContactFormOpen && <ContactForm closeContactForm={closeContactForm} />}
            </>
          )}
          {!searchCount && (
            <>
            <div style={{display:'none'}} className={`geosearch-m`}>
              <img
                src={spinD}
                alt="Donate"
                className="donate-icon"
                onClick={handleGifClick}
              />
            </div>
            </>
            
          )}

          {!isTopSearchActive && (
            <div className='search-container-mc search-cat-container-m'>
              <SearchBar
                classname="sc"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleSearch={handleSearch}
                isMobile={isMobile}
                handleFilter={handleFilter}
              />

              {showTooltip && (
                <Tooltip 
                isMobile={isMobile}
                handleClose={handleClose}
                />
              )}

              <div className="scrollable-list-mc"
                id="item-list">
              </div>

              <select
                className="sc categories-dropdown categories-dropdown-dc"
                value={selectedCategory}
                onChange={handleCategoryChange}
                title="Select Category"
                id="cat-dm"
              >
                <option value="all" disabled>Select a Category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          )}

        </>

      )}
      
      {!isMobile && (
        <>
          <div className={`geosearch`}>
            <img
              src={location_search_icon}
              alt="Location Search"
              className="geo-icon"
              style={{ height: '100%', width: '100%' }}
              onClick={handleSearchButtonClick}
            />
          </div>
          <div className={`ls language-selector-dt`}>
            <img
              src={glObr_Icon}
              alt="Language Selector"
              className="language-icon"
              style={{ height: '50px', width: '50px' }}
              onClick={handleLanguageIcon}
            />
          </div>
          <div className={`ls contact-icon-dt`}>
            <>
            <img
              src={questionMark}
              alt="Contact"
              className="language-icon"
              style={{ height: '50px', width: '50px' }}
              onClick={handleContactIconClick}
              title='contact Us'
            />
            {isContactFormOpen && <ContactForm closeContactForm={closeContactForm} />}
            </>
          </div>
          {showTooltip && (
                <Tooltip 
                isMobile={isMobile}
                handleClose={handleClose}
                />
            )}
          <div className={`${!searchCount ? "search-container-dc search-cat-container-dc" : "search-container-dt search-cat-container-dt"}`}>
            <SearchBar
              classname={`sc ${!searchCount ? "" : "search-width-d"}`}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleSearch={handleSearch}
              isMobile={isMobile}
              handleFilter={handleFilter}
            />

            
  
            <div className={`sl ${!searchCount ? "scrollable-list-dc" : "scrollable-list-dt"}`} 
            id="item-list">
            </div>

            <select
              className={`sc categories-dropdown ${!searchCount ? "categories-dropdown-dc" : "categories-dropdown-dt"}`}
              // className={` ${!searchCount ? "sc categories-dropdown categories-dropdown-dc" : "display-none"}`}
              value={selectedCategory}
              onChange={handleCategoryChange}
              title="Select Category"
              id="cat-d"
            >
              <option value="" disabled>Select a Category</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          
        </>
      )}

      {!isMobile && (
        <div style={{display:'none'}} className="spinD">
          <img
            src={spinD}
            alt="Donate"
            className="donate-icon"
            onClick={handleGifClick}
            style={{ cursor: 'pointer' }}
          />
        </div>
      )}

      {showLanguageDropdown && (
        <select
          className={`ld ${isMobile ? "language-dropdown": "language-dropdown-d"}`}
          data-placeholder="Select Language"
          data-toggle="tooltip"
          data-placement="top"
          title="Select Language"
          value={language}
          onChange={handleLanguageChange}
          translate="no"
        >
          {Object.keys(languageOptions).map((language, index) => (
            <option key={index} value={language}>
              {languageOptions[language] || language}
            </option>
          ))}
        </select>
      )}
      {showDonateButton && (
      <div className="donate-button" >
        <img
          src={paypalBtn}
          alt="Donate button"
          onClick={handleDonateClick}
          style={{ cursor: 'pointer' }}
          className="donate-image"
        />
        <div class="close-button" role="button" tabindex="0" onClick={handleDonateClose} aria-label="Close">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="25px" height="25px"><path d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z 
        M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	
        c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	
        c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z"/></svg>
        </div>

        {/* <button class="close-button" onClick={handleDonateClose} ><span aria-hidden="true">×</span></button> */}
      </div>
       )}

      {loading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
          background: 'rgba(255, 255, 255, 0.8)',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}>
          <img src="https://i.gifer.com/ZKZg.gif" alt="Loading..." style={{ width: '50px', height: '50px' }} />
        </div>
      )}

      {userLocation && (
        <MapContainer
          center={userLocation}
          zoom={13}
          style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 1 }}
          ref={setMap}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={userLocation} icon={HomeIcon}>
          </Marker>
          {markers.map((marker, index) => (
            marker && (
              <Marker
                key={index}
                position={[marker.lat, marker.lng]}
                icon={DefaultPinIcon}
                ref={el => markerRefs.current[index] = el} // Store marker reference
              >
                <Popup>
                  <div>
                    <a className='popup-link' href={marker.website} target="_blank" rel="noopener noreferrer">
                      <img src={marker.image} style={{ width: '100%', height: '221px' }} alt={marker.title} />
                      <h3 className='para_titile ellipsesT'>{marker.title}</h3>
                      {/* <p className='para_titile ellipsesP'>{marker.address}</p> */}
                    </a>
                  </div>
                </Popup>
              </Marker>
            )
          ))}
          <GeoSearchComponent />
        </MapContainer>
      )}

      <div className="info-boxes">
        {markers.length > 0 && markers.map((marker, index) => (
          <div
            key={index}
            className="info-box"
            style={{ backgroundImage: `url(${marker.image || 'https://via.placeholder.com/150'})` }}
            onClick={() => handleInfoBoxClick(marker, index, marker.lat, marker.lng)} // Trigger the popup and move to marker
          >
            <div className="info-title"><p>{marker.title}</p></div>
          </div>
        ))}
      </div>
      <div id="google_translate_element" style={{ display: 'none' }}></div>

    </div>
  );
};

export default App;
